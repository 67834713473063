import React from 'react';
import BTSSplash from '../resources/images/BTS/DanWorking.jpg';
import {Link} from 'react-router-dom'

function Splash() {
  return (
    <>
      <div 
          className="splash-img swatch-2"
          style={
            { backgroundImage: `url(${BTSSplash})`,
              backgroundSize: `cover`
           }
          }
      >
          <h3>Featured Content</h3>
          <h2>New TLINR Episodes</h2>
          <p>The Light Is Not Right is a happy product of our fascination with
the film industry's inner workings. Join us as we explore the
minds of seasoned veterans and special guests, on a quest
for constant movie magic.</p>
          <br />
          <p>
            <Link to="/tlinr" className="btn splash">
                Listen Now
            </Link>
          </p>
      </div>
    </>
  );
}

export default Splash;
;
