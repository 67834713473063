import Splash from './Components/Splash';
import Home from './Pages/Home';
import { Component } from 'react';

class App extends Component {
  render() {
    
    return (
      <>
          <Splash />
          <Home />
      </>
  );
}
}

export default App;
