import BehindTheScenes from '../Components/BehindTheScenes';
import BTSSplash from '../Components/BTSSplash';


function BTS() {
  return (
    <>
      <BTSSplash />
      <BehindTheScenes />
    </>
  );
}

export default BTS;
