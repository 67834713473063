import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../resources/images/brand/Labs_Symbol_01-01.svg";

function Header() {
  return (
    <>
      <header className="swatch-2">
          <div className="main-nav">
              <div className="main-nav-bar">
                 <div className="header-img">
                     <Link to="/">
                          <img src={logo} className="logo nav-item" alt=""/>
                     </Link>
                  </div>
                  <nav className="h4 font-1">
                      <ul className="header-nav">
                          <li>
                            <Link to="/productions" className="nav-item first">Productions</Link>
                           </li>
                          <li>
                            <Link to="/bts" className="nav-item">BTS</Link>
                          </li>
                          <li><Link to="/about" className="nav-item">About</Link></li>
                          <li><Link to="/contact-us" className="nav-item last">Contact</Link></li>
                      </ul>
                  </nav>
              </div>
          </div>
      </header>
    </>
  );
}

export default Header;
