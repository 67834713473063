import React from 'react';
import productions from '../Productions/ProductionsList';
import CallToAction2 from '../Components/CallToAction2';

const Film = ({match}) => {
    const name = match.params.name;
    const prod = productions.find( film => film.name === name);

    const castMembers = [];
    const crewMembers = [];
    for (const cast of prod.crewAndCast.cast) {
        castMembers.push(
                    <tr>
                    <th>{cast[0]}
                    </th>
                    <th>
                        {cast[1]}
                    </th>
                    </tr>
        )
    } 

    for (const crew of prod.crewAndCast.crew) {
        crewMembers.push(
                    <tr>
                    <th className="actor-info">
                        {crew[0]}
                    </th>
                    <th className="actor-info">
                        {crew[1]}
                    </th>
                    </tr>
        )
    }

    const theStoryBlurb = [];
    for (const storyblurb of prod.story) {
        theStoryBlurb.push(
            <p>
            {storyblurb}
            </p>
        )
    };

    const theFilmBlurb = [];
    for (const filmblurb of prod.theFilm) {
        theFilmBlurb.push(
            <p>
            {filmblurb}
            </p>
        )
    };

    const theChallengeBlurb = [];
    for (const chalblurb of prod.theChallenge) {
        theChallengeBlurb.push(
            <p>
            {chalblurb}
            </p>
        )
    };

    const theInspirationBlurb = [];
    for (const inspirationblurb of prod.theInspiration) {
        theInspirationBlurb.push(
            <p>
            {inspirationblurb}
            </p>
    )
    };


    return (
        <>
            <main className="index body-set swatch-1">
                <section className="movie-header">
                    <h1 className={prod.swatch1}>{prod.title}</h1>
                    <h2 className="director" >Dir. {prod.crewAndCast.director}, {prod.date}</h2>
                    {
                    prod.videoURL === ''? null: 
                        <div className="iframeDiv"> 
                            <iframe src={prod.videoURL} title={prod.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen width="100%">
                            </iframe>
                        </div>
                    }
                </section>
                <section className="movie-info">
                    <section className="film-info">
                        <div className={`text-block`}>
                        <h2>The Story</h2>
                            <h3 className={`${prod.swatch3}-alt`}>
                                {prod.quote}
                            </h3>
                            {theStoryBlurb}
                        </div>
                        <div className="film-info-img">
                            <img src={prod.storyIMG} alt="" />
                        </div>
                    </section>
                    <section className="film-info">
                    {
                            prod.theChallengeIMG2 === ''?
                            <div className="film-info-img">
                                    <img src={prod.theFilmIMG} alt="" className="desktop-img"/><br/>
                            </div>
                                : 
                        <div className="film-info-img-stack">
                            <div className="stacked-top-2">
                                <img src={prod.theFilmIMG} alt="" className="desktop-img"/> 
                            </div>
                            <div className="stacked-bottom-2">
                                <img src={prod.theFilmIMG2} alt="" className="desktop-img"/> 
                            </div>
                        </div>
                    }
                        <div className={`text-block odd inspo`}>
                            <h2>The Film</h2>
                            <h3 className={`${prod.swatch1}-alt`}>
                                {prod.theFilmQuote}
                            </h3>
                            {theFilmBlurb}
                        </div>
                            <img src={prod.theFilmIMG} alt="" className="mobile-img"/> 
                    </section>
                    <section className="film-info">
                        <div className={`text-block`}>    
                            <h2>The Challenge</h2>
                            <h3 className={`${prod.swatch2}-alt`}>
                                {prod.theChallengeQuote}
                            </h3>
                            {theChallengeBlurb}
                        </div>
                        {
                            prod.theChallengeIMG2 === ''?
                            <div className="film-info-img">
                                    <img src={prod.theChallengeIMG} alt=""/><br/>
                            </div>
                                : 
                            <div className="film-info-img-stack">
                                <div className="stacked-top">
                                    <img src={prod.theChallengeIMG} alt=""/><br/>
                                </div>
                                <div className="stacked-bottom">
                                    <img src={prod.theChallengeIMG2} alt="" className="desktop-img"/>
                                </div>
                            </div>
                         }
                    </section>
                    <section className="film-info">
                        <div className="film-info-img">
                            <img src={prod.theInspirationIMG} alt="" className="desktop-img odd"/> 
                        </div>
                        <div className={`text-block odd inspo`}>
                            <h2>The Inspiration</h2>
                            <h3 className={`${prod.swatch3}-alt`}>
                                {prod.theInspirationQuote}
                            </h3>
                            {theInspirationBlurb}
                        </div>
                            <img src={prod.theInspirationIMG} alt="" className="mobile-img"/> 
                    </section>
                </section>
                <section className={`cast-info cast-crew-table`}>
                    <h2>The Cast</h2>
                    <table className={`${prod.swatch1}`}>
                        {castMembers}
                    </table>
                </section>
                <br/><br/>
                <section className="core-crew cast-crew-table">
                    <h2>Core Crew</h2> 
                    <table className={`${prod.swatch1}`}>
                        {crewMembers}
                    </table>
                </section>
            </main>
                {/* <CallToAction1 /> */}
                <CallToAction2 />
        </>
    );
}

export default Film;
