import React from 'react';
import George from '../resources/images/team/George_team.jpg';
import Guy from '../resources/images/team/Guy_team.jpg';
import { Link } from 'react-router-dom';
import CallToAction2 from '../Components/CallToAction2';
import Code1 from '../resources/images/about/Code-1.jpg'
import Code2 from '../resources/images/about/Code-2.jpg'
import Code3 from '../resources/images/about/Code-3.jpg'

function About() {
  return (
    <>
 <main className="index body-set">
        <section className="about">
            <h3>About Us</h3>
            <h2>We're a Canadian Indie Film Studio</h2>
            <p>
                We're captivated with the quiet struggles we all face and the wild adventures that overcome them. 
                <br /><br />
                We tell the stories and celebrate the moments that shape us all - the highs, the lows, and the normal that's never mundane.
                <br /><br/>
                Disheartened by today's industry norms, we founded Khaos Labs to explore better ways of creating good work. In the process, we've established three tenets that guide our every decision.
            </p>
        </section>
        <section>
            <div className="content-section code-blocks">
                <section className="value-block swatch-3">
                    <img src={Code1} alt="On the set of American Dreamers"/>
                    <div className="text-block">
                        <h4>Code #1</h4>
                        <h3>Champion Humans</h3>
                        <br/>
                        <p>Love your team. Support your partners. Empower your fans. The people matter far more than the work.</p>
                    </div>
                </section>
                <section className="value-block swatch-7">
                    <img src={Code2} alt="On the set of American Dreamers"/>
                    <div className="text-block">
                        <h4>Code #2</h4>
                        <h3>Experiment Incessantly</h3>
                        <br/>
                        <p>We play with the unknown, because that's how everything gets better. Failure is an option. Boredom is not.</p>
                    </div>
                </section>
                <section className="value-block swatch-8">
                    <img src={Code3} alt="Guy with co-writer of American Dreamers, James Roberts"/>
                    <div className="text-block">
                        <h4>Code #3</h4>
                        <h3>Break The Wall</h3>
                        <br/>
                        <p>The stories happening behind the camera are as important as the ones shot in front of it. We think it's time you heard those too.</p>
                    </div>
                </section>
            </div>
        </section>
        <aside className="about-BTS" >
            <p className="swatch-5">
                <Link to="/bts" className="">
                    See how these codes have transformed our projects and discover how they bring people together in our Behind the Scenes Content. <br/> →
                </Link>
            </p>
        </aside>
        <section className="body-section about"> 
            <h2>It's all about the People.</h2>
            <p className="Collaborators-blurb">Our collaborators live across the globe, from the UK, to France, to Columbia, to the US, and in Canada from East to West Coast.</p>
            <h3> Meet the founding team</h3>
            <div className="content-section">
                <section className="team-card swatch-6">
                    <img src={George} alt="George Popi" />
                    <h3>George Popi</h3>
                    <h4>Managing Director</h4>
                    <br/>
                    <p>
                        With boundless energy and compassion George obssesses over who we are, and more importantly, who we want to become.
                        <br />
                        <br />
                    </p>
                </section>
                {/* <section className="team-card swatch-8">
                    <img src="./resources/images/team/Emily_team.jpg" alt="Emily Sheeran" />
                    <h3>Emily</h3>
                    <h4>Production Manager</h4>
                    <p>
                        Her energy and versatility have made her an invaluable member of the team. Beyond Emily's undeniable eye for talent across pre-production, she also ensures that our sets run smoothly and efficiently—despite all the odds!
                    </p>
                </section> */}
            </div>
        </section>
        {/* <section>
            <h3>Get In Touch</h3>
            <h2>Come Work with Us!</h2>
            <p>
                Have a story that you want to tell to the world? Submit your script ideas or talk with our production team to learn how we bring projects together.
            </p>
            <a href="./contact.html" className="btn3">Start Now</a>
        </section> */}
    </main>
        <CallToAction2/>
    </>
  );
}

export default About;
