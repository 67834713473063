import React from 'react';
import TLINRList from '../Productions/TLINRList';
import CallToAction from '../Components/CallToAction';

const Projects = () => {
    const Ep1 = TLINRList.find (tlinr => tlinr.epNum === 1 );
    const Ep2 = TLINRList.find (tlinr => tlinr.epNum === 2 );
    const Ep3 = TLINRList.find (tlinr => tlinr.epNum === 3 );
    const Ep4 = TLINRList.find (tlinr => tlinr.epNum === 4 );
    const Ep5 = TLINRList.find (tlinr => tlinr.epNum === 5 );
    const Ep6 = TLINRList.find (tlinr => tlinr.epNum === 6 );

    return (
    <>
      <main className="index body-set">
            <section className="film-info">

            <iframe width="560" height="315" src={Ep1.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           

                <div className="text-block">
                    <h4>{`Episode ${Ep1.epNum} – ${Ep1.guest}`}</h4>
                    <h3>{Ep1.title}</h3>
                    <p>{Ep1.logline}</p>
                    <p>
                        <a href={Ep1.videoURL} className="btn">Watch on Youtube</a>
                    </p>
                </div>
            </section>
            <section className="film-info">
            <iframe width="560" height="315" src={Ep2.videoEmbed} title={Ep2.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="text-block">
                    <h4>{`Episode ${Ep2.epNum} – ${Ep2.guest}`}</h4>
                    <h3>{Ep2.title}</h3>
                    <p>{Ep2.logline}</p>
                    <p>
                        <a href={Ep2.videoURL} className="btn">Watch on Youtube</a>
                    </p>
                </div>
            </section>
            <section className="film-info">
            <iframe width="560" height="315" src={Ep3.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="text-block">
                     <h4>{`Episode ${Ep3.epNum} – ${Ep3.guest}`}</h4>
                    <h3>{Ep3.title}</h3>
                    <p>{Ep3.logline}</p>
                    <p>
                        <a href={Ep3.videoURL} className="btn">Watch on Youtube</a>
                    </p>
                </div>
            </section>
            <section className="film-info">
            <iframe width="560" height="315" src={Ep4.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="text-block">
                   <h4>{`Episode ${Ep4.epNum} – ${Ep4.guest}`}</h4>
                    <h3>{Ep4.title}</h3>
                    <p>{Ep4.logline}</p>
                    <p>
                        <a href={Ep4.videoURL} className="btn">Watch on Youtube</a>
                    </p>
                </div>
            </section>
            <section className="film-info">
            <iframe width="560" height="315" src={Ep5.videoEmbed} title={Ep5.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <div className="text-block">
                    <h4>{`Episode ${Ep5.epNum} – ${Ep5.guest}`}</h4>
                    <h3>{Ep5.title}</h3>
                    <p>{Ep5.logline}</p>
                    <p>
                        <a href={Ep5.videoURL} className="btn">Watch on Youtube</a>
                    </p>
                </div>
            </section>
            <section className="film-info">
            <iframe width="560" height="315" src={Ep6.videoEmbed} title={Ep5.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="text-block">
                    <h4>{`Episode ${Ep6.epNum} – ${Ep6.guest}`}</h4>
                    <h3>{Ep6.title}</h3>
                    <p>{Ep6.logline}</p>
                    <p>
                        <a href={Ep6.videoURL} className="btn">Watch on Youtube</a>
                    </p>
                </div>
            </section>
        {/* <section className="cta">
            <h3>Get In Touch</h3>
            <h2>Come Work with Us!</h2>
            <p>
                Have a story that you want to tell to the world? Submit your script ideas or talk with our production team to learn how we bring projects together.
            </p>
            <p>
                <Link to="./contact-us" className="btn">Start Now</Link>
            </p>
        </section> */}
      </main>
        <CallToAction />
    </>
  );
}

export default Projects;
