import TLINRSplash from '../Components/TLINRSplash';
import TLINRLog from '../Components/TLINRLog';


function TLINR() {
  return (
    <>
      <TLINRSplash />
      <TLINRLog />
    </>
  );
}

export default TLINR;
