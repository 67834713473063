import React from 'react';
import AmDSplash from '../resources/images/splash-images/TLINR_Splash.jpg';

function Splash() {
  return (
    <>
      <div 
          className="splash-img swatch-2"
          style={
            { backgroundImage: `url(${AmDSplash})`,
              backgroundSize: `cover`
           }
          }
      >
          <h3>Featured Content</h3>
          <h2>New TLINR Episodes</h2>
          <p>The Light Is Not Right is a product of our fascination with the film industry’s more obscure elements. Join us as we explore the minds of seasoned veterans and special guests, on a quest for the inner workings of real movie magic.</p>
          <br />
      </div>
    </>
  );
}

export default Splash;
;
