import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Productions from './Pages/Productions';
import About from './Pages/About';
import BTS from './Pages/BTS';
import Contact from './Pages/Contact';
import Film from './Pages/Film';
import TLINR from './Pages/TLINR';
import TLINREp from './Pages/TLINREp';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <div className="swatch-1">
      <Header />
        <Route path='/' component={App} exact />
        <Route path='/productions' component={Productions} exact/>

        <Route path='/about' component={About} exact />
        <Route path='/bts' component={BTS} exact />
        <Route path='/contact-us' component={Contact} exact />
        <Route path='/productions/:name' component={Film} />
        <Route path='/TLINR' component={TLINR} />
        <Route path='/TLINR/:name' component={TLINREp} />

      
      <Footer />
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
