const TLINRList = [
    {
        epNum: 1, //Immutable Field
        name: `american-dreamers`,
        title: `The Off Screen Arms Race`,
        guest: `Deborah Sheppard`,
        logline: `High off our latest production, American Dreamers, we dive deep into the challenges of film releases with former UIP movie Marketing Director Deborah Sheppard. Plus, the changes shaping distribution and the value of forecasting returns.`,
        quote: `The tactics have changed, but the principles are the same`,
        crewAndCast: 
            {
                director: 'Guy Ettlin',
                cast:   [
                    ["",""],
                    ["",""]
                ],
                crew:   [
                    ["",""],
                    ["",""]
                ],
            },
        date: 2020,
        videoEmbed: `https://www.youtube.com/embed/E1gNssXc6YA`,
        videoURL: `https://www.youtube.com/watch?v=E1gNssXc6YA`,
        // poster: `./resources/images/productions/AmericanDreamers_productions.jpg`,
        swatch1: "swatch-4",
        swatch2: "swatch-5",
        swatch3: "swatch-7"
    },
    {
        epNum: 2, //Immutable Field
        name: `jessica`,
        guest: `Mark Jones`,
        title: `Collaboration is Key`,
        logline: `Moving deeper into the film release process, we talk about cultivating the necessary trust between filmmakers and publicists with Distribution Consultant Mark Jones. Plus, the opportunities and challenges of a digital world.`,
        quote: `Docs are easy now, you can edit it on your computer and film it on your phone.`,
        crewAndCast: 
            {
                director: 'Clara Butler',
                cast:   [
                    ["",""],
                    ["",""]
                ],
                crew:   [
                    ["",""],
                    ["",""]
                ],
            },
        date: 2020,
        poster: ``,
        videoEmbed: `https://www.youtube.com/embed/gcRSInd2MpA`,
        videoURL: `https://www.youtube.com/watch?v=gcRSInd2MpA`,
        swatch1: "swatch-8",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    },
    {
        epNum: 3, //Immutable Field
        name: `spark`,
        guest: `Christopher Hird`,
        title: `Plan it From the Start`,
        logline: `A glimpse into the magic and pain of true passion. A street performer struggles to follow his childhood dream and spread a little joy through an uncaring city. A modern silent film in five acts.`,
        quote: `Turning to the niche of first-time filmmakers, we discuss the process of developing nascent documentaries with a British titan of the industry, Christopher Hird. Plus, gathering early funding and making the best of a small budget.`,
        crewAndCast: 
            {
                director: 'Guy Ettlin',
                cast:   [
                    ["",""],
                    ["",""]
                ],
                crew:   [
                    ["",""],
                    ["",""]
                ],
            },
        date: 2020,
        poster: ``,
        videoEmbed:`https://www.youtube.com/embed/NLNVhPYIsOA`,
        videoURL: `https://www.youtube.com/watch?v=NLNVhPYIsOA`,
        swatch1: "swatch-5",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    },
    {
        epNum: 4, //Immutable Field
        name: `swim-alone`,
        guest: `Zsófi Szemerédy`,
        title: `Don’t let a little “no” stop you`,
        logline: `Expanding on the challenges of indie film creators, we chat with Indie Film Distribution Summit Co-founder, Zsófi Szemerédy, about the importance of knowing your audience. Plus, the value of creative career choices and perseverance.`,
        quote: `What do you do when you get a “no”? You ask a few more people!`,
        crewAndCast: 
            {
                director: 'Guy Ettlin',
                cast:   [
                    ["",""],
                    ["",""]
                ],
                crew:   [
                    ["",""],
                    ["",""]
                ],
            },
        date: 2020,
        poster: `https://youtu.be/zat2ZuDuIRs`,
        videoEmbed: `https://www.youtube.com/embed/zat2ZuDuIRs`, 
        videoURL: `https://www.youtube.com/watch?v=zat2ZuDuIRs`,
        swatch1: "swatch-3",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    },
    {
        epNum: 5, //Immutable Field
        name: `senna`,
        guest: `Ben Doyle`,
        title: `Only tell the story you can tell`,
        logline: `YouTuber, and documentary filmmaker Ben Doyle explains how the KSI documentary “Can’t Lose” came into production. He also breaks down his road into movie production and where he stands between the YouTube world and the traditional production world.`,
        crewAndCast:
            {
                director: 'Sadia Rafiquddin',
                cast:   [
                            ["",""],
                            ["",""]
                        ],
                crew:   [
                            ["",""],
                            ["",""]
                        ]
            },
        date: 2020,
        poster: ``,
        videoEmbed: `https://www.youtube.com/embed/0LUYhATI-n4`,
        videoURL: `https://www.youtube.com/watch?v=0LUYhATI-n4`,
        swatch1: "swatch-7",
        swatch2: "swatch-1",
        swatch3: "swatch-1",
    },
    {
        epNum: 6, //Immutable Field
        name: `senna`,
        guest: `Christian Siddell`,
        title: `Musicology & Copyright`,
        logline: `Musicologist and copyright specialist Christian Siddell, explains the often under appreciated world of copyright to James. He tells us the most common mistakes he sees in productions, and breaks down best practices when creating a feature in terms of music and funds allocation.`,
        quote: `The landscape of rights is something which needs to be given proper consideration`,
        crewAndCast:
            {
                director: 'Sadia Rafiquddin',
                cast:   [
                            ["",""],
                            ["",""]
                        ],
                crew:   [
                            ["",""],
                            ["",""]
                        ]
            },
        date: 2020,
        poster: ``,
        videoEmbed:`https://www.youtube.com/embed/KlKjFxkwPc0`,
        videoURL: `https://www.youtube.com/watch?v=KlKjFxkwPc0`,
        swatch1: "swatch-7",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    }
    
    // {
    //     name: ``,
    //     title: ``,
    //     genre: ``,
    //     logline: ``,
    //     quote: ``,
    //     story: ``,
    //     theFilmQuote: ``,
    //     theFilm: ``,
    //     theChallengeQuote: ``,
    //     theChallenge: ``,
    //     theInspirationQuote: ``,
    //     theInspiration: ``,
    //     crewAndCast: 
    //         {
    //             director: '',
    //             cast:   [
    //                         ["",""],
    //                         ["",""]
    //                     ],
    //             crew:   [
    //                         ["",""],
    //                         ["",""]
    //                     ]
    //         },
    //     date: 2020,
    //     poster: ``,
    //     videoURL: ``,
    //     swatch1: ``,
    //     swatch2: ``,
    //     swatch3: ``,
    // },
];



export default TLINRList