import React from 'react';
import AmDSplash from '../resources/images/splash-images/AmericanDreamers_Splash_1.jpg';
import { Link } from 'react-router-dom';

function Splash() {
  return (
    <>
      <div 
          className="splash-img swatch-2"
          style={
            { backgroundImage: `url(${AmDSplash})`,
              backgroundSize: `cover`
           }
          }
      >
          <h3>Featured Content</h3>
          <h2>American Dreamers Trailer</h2>
          <p>A journey that explores the mess of life in early adulthood. Two British graduates take a roadtrip across the USA seeking a shortcut to fame, fortune, and green cards.</p>
          <br />
          <p>
            <Link to="/productions/american-dreamers" className="btn splash">
              Watch Now
            </Link>
          </p>
      </div>
    </>
  );
}

export default Splash;
