import React from 'react';
import BTSList from '../Productions/BTSList';
import CallToAction from '../Components/CallToAction2';
 
function Projects() {
    const Ep1 = BTSList.find (bts => bts.epNum === 1 );
    const Ep2 = BTSList.find (bts => bts.epNum === 2 );
    const Ep3 = BTSList.find (bts => bts.epNum === 3 );
    const Ep4 = BTSList.find (bts => bts.epNum === 4 );
    const Ep5 = BTSList.find (bts => bts.epNum === 5 );
    const Ep6 = BTSList.find (bts => bts.epNum === 6 );
    const Ep7 = BTSList.find (bts => bts.epNum === 7 );
    const Ep8 = BTSList.find (bts => bts.epNum === 8 );
    const Ep9 = BTSList.find (bts => bts.epNum === 9 );
  
    return (
    <>
        <main className="index body-set">
            <h1>Behind the Scenes</h1>
                <h2>American Dreamers</h2>
            <section className="film-info">
                    
                        <iframe width="560" height="315" src={Ep1.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                    
                        <iframe width="560" height="315" src={Ep2.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                    
                        <iframe width="560" height="315" src={Ep3.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
            </section>
                <h2 id="sparkBTS">Spark</h2>
            <section className="film-info">
                <iframe width="560" height="315" src={Ep4.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="315" src={Ep5.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="315" src={Ep6.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </section>
                <h2>Jessica</h2>
            <section className="film-info">
                <iframe width="560" height="315" src={Ep7.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="315" src={Ep8.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe width="560" height="315" src={Ep9.videoEmbed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </section>
        </main>
            <CallToAction />
    </>
  );
}

export default Projects;
