import React from 'react';
import productions from '../Productions/ProductionsList';
import { Link } from 'react-router-dom';
import PosterAmD from '../resources/images/productions/AmericanDreamers_productions.jpg';
import PosterJes from '../resources/images/productions/Jessica_productions.png';
import PosterSpark from '../resources/images/productions/Spark_productions.jpg';
import PosterSA from '../resources/images/productions/SwimAlone_productions.jpg';
import PosterSenna from '../resources/images/productions/Senna_productions.jpg';
import CallToAction from '../Components/CallToAction';

const Projects = () => {
    const AmD = productions.find (prod => prod.name === `american-dreamers` );
    const Jes = productions.find (prod => prod.name === `jessica` );
    const SA = productions.find (prod => prod.name === `swim-alone` );
    const Spark = productions.find (prod => prod.name === `spark` );
    const Senna = productions.find (prod => prod.name === `senna` );

    return (
    <>
      <main className="index body-set">
        <section className="features">
            <h2>Features</h2>
            <section className="prod-info">
                <img src={PosterAmD} alt="" className="desktop-img"/>
                <div className="text-block">
                    <h4>Dir. {AmD.crewAndCast.director}</h4>
                    <h3>{AmD.title}</h3>
                    <img src={PosterAmD} alt="" className="mobile-img"/>
                    <p>{AmD.logline}</p>
                    <p>
                        <Link to={`./productions/${AmD.name}`} className="btn">Learn More</Link>
                    </p>
                </div>
            </section>
        </section>
        <section className="shorts">
            <h2>Shorts</h2>
            <section className="prod-info odd">
                <div className="text-block">
                    <h4>Dir. {Jes.crewAndCast.director}</h4>
                    <h3>{Jes.title}</h3>
                    <img src={PosterJes} alt="" className="mobile-img"/>
                    <p>{Jes.logline}</p>
                    <p>
                        <Link to={`./productions/${Jes.name}`} className="btn">Learn More</Link>
                    </p>
                </div>
                <img src={PosterJes} alt="" className="desktop-img"/>
            </section>
            <section className="prod-info">
                <img src={PosterSpark} alt="" className="desktop-img"/>
                <div className="text-block">
                    <h4>Dir. {Spark.crewAndCast.director}</h4>
                    <h3>{Spark.title}</h3>
                    <img src={PosterSpark} alt="" className="mobile-img"/>
                    <p>{Spark.logline}</p>
                    <p>
                        <Link to={`./productions/${Spark.name}`} className="btn">Learn More</Link>
                    </p>
                </div>
            </section>
            <section className="prod-info odd">
                <div className="text-block">
                    <h4>Dir. {SA.crewAndCast.director}</h4>
                    <h3>{SA.title}</h3>
                    <img src={PosterSA} alt="" className="mobile-img"/>
                    <p>{SA.logline}</p>
                    <p>
                        <Link to={`./productions/${SA.name}`} className="btn">Learn More</Link>
                    </p>
                </div>
                <img src={PosterSA} alt="" className="desktop-img"/>
            </section>
        </section>
        <section className="documentaries">
            <h2>Documentaries</h2>
            <section className="prod-info">
                <img src={PosterSenna} alt="" className="desktop-img"/>
                <div className="text-block">
                    <h4>Dir. {Senna.crewAndCast.director}</h4>
                    <h3>{Senna.title}</h3>
                    <img src={PosterSenna} alt="" className="mobile-img"/>
                    <p>{Senna.logline}</p>
                    <p>
                        <Link to={`./productions/${Senna.name}`} className="btn">Learn More</Link>
                    </p>
                </div>
            </section>
        </section>
        {/* <section className="cta">
            <h3>Get In Touch</h3>
            <h2>Come Work with Us!</h2>
            <p>
                Have a story that you want to tell to the world? Submit your script ideas or talk with our production team to learn how we bring projects together.
            </p>
            <p>
                <Link to="./contact-us" className="btn">Start Now</Link>
            </p>
        </section> */}
      </main>
        <CallToAction />
    </>
  );
}

export default Projects;
