import React from 'react';
import { Link } from 'react-router-dom';

function CallToAction2() {


    return (
    <>
        <section className="swatch-8">
            <div className="cta swatch-8">
                <h3>Get In Touch</h3>
                <h2>Come Work with Us!</h2>
            </div>
            <div className="cta swatch-7">
                <p>
                    Have a story that you want to tell to the world? Submit your script ideas or talk with our production team to learn how we bring projects together.
                </p>
                <p>
                    <Link to="./contact-us" className="btn">Start Now</Link>
                </p>
            </div>
        </section>
    </>
  );
}

export default CallToAction2;
