import Splash from '../Components/Splash';
import Projects from '../Components/Projects';


function Productions() {
  return (
    <>
      <Splash />
      <Projects />
    </>
  );
}

export default Productions;

