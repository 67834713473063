const productions = [
    {
        name: `american-dreamers`, //Immutable Field
        title: `American Dreamers`, 
        genre: `Feature Film`, 
        logline: 'A coming-of-age exploration of privilege and national identity on an intense journey down the West Coast of the USA to Las Vegas. Two British twenty-somethings embark on a road trip seeking a shortcut to fame, fortune, and green cards.',
        quote: `"I'm telling you, if we polish up our pickup hacks we can find two girls to marry us!"`,
        story: [`London-based self-development junkies Phil and Andrew have convinced themselves they’ve found the shortcut to fame and success: they board a plane to America looking for a couple of green card marriages.`,
            `Meanwhile, California-based Mary and Katherine leave their home in the Bay Area to shoot a documentary about the contemporary state of the American Dream in 2021.`,
            `When the two Brits meet the Cali duo, the naive lifehackers are faced with a radical new perspective on entitlement and modern adulthood. What ensues is a coming-of-age exploration of privilege and national identity on an intense journey down the West Coast to Las Vegas.`],
        storyIMG: "../images/productions/AmD/EPK-Stills_1.548.1.jpg",
        theFilmQuote: `"I think the UK is the main thing holding us back right now."`,
        theFilm: [`American Dreamers was born when writers Guy and James started joking about leaving for the US, getting married in Vegas, and becoming instantly famous by recording every moment of their lives. Though it started as a silly hypothetical, they realized that there was a deeper element to the premise - strangely representative of many people in their 20s, floating in the void without direction, latching onto self-improvement trends, hungry for success and desperate for a sense of purpose. It would quickly form the premise for a seemingly light-hearted buddy comedy, all the while hinting at the inequalities baked into the modern US immigration system, and the entitlement that the male protagonists embody.`,
            `What resulted is a story about modern adulthood, American idealism, national identity, and the varying forms of privilege we encounter every day. Painfully unaware of their own privilege, our male protagonists easily blur the line between heros and anti-heros.`],
        theFilmIMG: "../images/productions/AmD/EPK-Stills_1.931.1-1-p-2600.jpeg",
        theFilmIMG2: "../images/productions/AmD/TheFilm2.jpg",
        theChallengeQuote: `"It's really f****** crazy! Exactly and that's why it's gonna f****** work!"`,
        theChallenge: [`The production of American Dreamers spanned 22 shooting days, across 50 locations, and three countries. It was shot for under $100,000 and looks like a million bucks.`,
            `Due to the limited resources available to first-time filmmakers, we developed an innovative micro-budget concept that would allow us to produce a full-length feature with aesthetics similar to those of of much larger productions.`,
            `Our constraints inspired us to reexamine guerrilla  production techniques and customize a seamless shooting process, using an RV as transportation, accommodation, prop, and action vehicle.`,
            `Aware that we were journeying far into the unknown with American Dreamers, we prioritized versatility when planning, equipping, and recruiting for production. We were able to film at a moment’s notice, harnessing coincidences and instances of pure inspiration throughout the trip. Some of those unscripted scenes are now our favourite and fit perfectly within the plot – an unexpected benefit of living the story, whilst shooting it.`],
        theChallengeIMG: "../images/productions/AmD/MG1.jpg",
        theChallengeIMG2: "../images/productions/AmD/TheChallenge2.jpeg",
        theInspirationQuote: `"Yesterday, I had hope. Now, I just have a diploma..."`,
        theInspiration: [`American Dreamers is a reaction to a peculiar phenomenon we increasingly encounter in today's world. Far and wide, we see insecure overachievers entering adulthood with unprecedented options and privileges. Some of them broadly denounce corporate structure, looking for something more than a steady pay-cheque, yet they fall apart the second those structures no longer support their daily lives. When writer Guy and James started developing those ideas into a film, they wondered what would happen if they took their own greatest insecurities, the ones growing daily, and shaped them into characters. This is the result.`],
        theInspirationIMG: "../images/productions/AmD/EPK-Stills1.jpg",
        crewAndCast: 
            {
                director: 'Guy Ettlin',
                cast:   [
                            ['Andrea Pavlovic','Mary'],
                            ["Teddy Robson","Andrew"],
                            ["James Roberts","Phil"],
                            ["Aisha Blondeau","Katherine"],
                            ["Phil Guerrero","Jordan Willis"]
                        ],
                crew:   [
                            ["Guy Ettlin","Director"],
			   ["Guy Ettlin and George Popi","Producers"],
                            ["Emily Sheeran","Unit Production Manager"],
                            ["Guy Ettlin and James Roberts","Writers"],
                            ["Daniel Everitt-Lock","Director of Photography"],
                            ["Rodrigo Borda","1st Assistant Camera"],
                            ["Keegan Boulineau","Sound Recordist"],
                            ["Gaspard Nahmias","Gaffer"],
                            ["Alexander Mackay","KeyGrip"]
                        ]
            },
        date: 2020,
        videoURL: `https://www.youtube.com/embed/iWJ-N0wAPfY`,
        poster: "../images/index/Poster-AmD.jpg",
        swatch1: "swatch-4",
        swatch2: "swatch-5",
        swatch3: "swatch-7"
    },
    {
        name: `jessica`, //Immutable Field
        title: `Jessica`,
        genre: `Short Film`,
        logline: `A story about personal truths being hidden from us in plain daylight. The homeless Jessica will go to any length to spend one more night in a soft, clean bed.`,
        quote: ``,
        story: [`Without a safety net to fall back on, the homeless Jessica is forced to live in her car on the outskirts of London. At the end of her rope, she gets ready to go to a local club with the aim of finding a bed to sleep in for the night.`],
        storyIMG: `../images/productions/Jessica/Jessica-1.png`,
        theFilmQuote: ``,
        theFilm: [`Our third short, Jessica is our first collaboration with an external team. When Producer Guy Ettlin heard Writer Clara Butler’s pitch at an event in London, he was inspired to help her bring the short to life. Given the creative lead, she and her hand-picked team of all-female collaborators shot this exceptional short over the course of 2 days. The result is a simply executed yet highly powerful portrayal of hidden homelessness, done in a raw and honest way.`],
        theFilmIMG: `../images/productions/Jessica/Jessica-2.png`,
        theFilmIMG2: "",
        theChallengeQuote: ``,
        theChallenge: [`Yielding creative direction to Clara and her team was a real test of our culture and mission to empower all storytellers. We now know that trusting talented creators means trusting unfamiliar processes. When you give control to another, do so fully and wholeheartedly. `],
        theChallengeIMG: `../images/productions/Jessica/Jessica-3.png`,
        theChallengeIMG2: "",
        theInspirationQuote: ``,
        theInspiration: [`Jessica depicts a keyhole moment in the life of an anonymous friend of Clara’s, the Writer/Director, as she suffered on the streets of London. Having herself experienced the trials of homelessness, Clara was inspired to write and share her friend’s story, conveying the often underrepresented issue of female homelessness, in particular, hidden homelessness. Jessica is a reminder of the fragility that is inherent to the lives of so many of us.`,
            `We hope that Jessica will draw attention to the various ways that the homeless mask their situation, despite magnifying their shame.`],
        theInspirationIMG: `../images/productions/Jessica/Jessica-4.png`,
        crewAndCast: 
            {
                director: 'Clara Butler',
                cast:   [
                            ["Amy Bowden","Jessica"],
                            ["David Flannagan","Tony"]
                        ],
                crew:   [
                            ["Clara Butler","Director"],
                            ["Anastasiya Cherkasyuk","Director of Photography"],
                            ["James Janna Bartlett","Production Designer"],
                            ["Emily Sheeran","Production Manager"],
                            ["Sophie Wyatt","Hair and Makeup"]
                        ]
            },
        date: 2020,
        poster: `https://drive.google.com/file/d/1Ph1mqSqa30TbYAN0IwtNPFwfrzV1Z574/view?usp=sharing`,
        videoURL: ``,
        swatch1: "swatch-8",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    },
    {
        name: `spark`, //Immutable Field
        title: `Spark`,
        genre: `Short Film`,
        logline: `A glimpse into the magic and pain of true passion. A street performer struggles to follow his childhood dream and spread a little joy through an uncaring city. A modern silent film in five acts.`,
        quote: ``,
        story: [`A glimpse into the magic and the pain of true passion, as a street performer struggles to follow his childhood dream and spread some joy through an uncaring city. A modern silent film in five acts.`],
        storyIMG: `../images/productions/Spark/Spark-1.png`,
        theFilmQuote: ``,
        theFilm: [`Spark was written at a time when Writer and Director Guy Ettlin felt that London was slowly killing his dreams. For him, this story’s power lies in the influence that past—even bygone—dreams hold over our future goals and the schism that occurs as a result.`],
        theFilmIMG: `../images/productions/Spark/Spark-2.png`,
        theFilmIMG2: "",
        theChallengeQuote: ``,
        theChallenge: [`Once we entered the project, we were overwhelmed with the complexity of expressing the short’s central theme—the convergence and divergence of the old with the new. We knew that steeping every aspect of the film (sound, location, story, etc.) in this duality would test our sense of creativity. Despite this complication, the seamless push-and-pull effect allowed us to really bring Spark’s inherent conflict to life. `],
        theChallengeIMG: `../images/productions/Spark/Spark-3.png`,
        theChallengeIMG2: "",
        theInspirationQuote: ``,
        theInspiration: [`After repeatedly crossing the same ignored busker outside one of London’s busiest subway stations, Guy was inspired to share the juggler’s struggle. Touched with the man’s drive to be seen, Guy used the romance of nostalgia and the grief of wavering mental health to bring this story to life.‍`],
        theInspirationIMG: `../images/productions/Spark/Spark-4.png`,
        crewAndCast: 
            {
                director: 'Guy Ettlin',
                cast:   [
                            ["Brant Matthews","Peter"],
                            ["Theo Simons","Kid"],
                            ["Michael Loewen","Father"],
                            ["Matthew McKeown","Grocery Man"],
                            ["Nicole Arrage & Steven Finley","Couple"]
                        ],
                crew:   [
                            ["Guy Ettlin","Director, Writer, Producer"],
                            ["George Popi","Executive Producer"],
                            ["Robin L'Houmeau","Director of Photography"],                            
                            ["Alexis LP","Original Soundtrack"],
                            ["Kayla Shears","Sound Design"],
                            ["Gaspard Nahmias","Gaffer"],
                            ["Alexander Mackay","Grip"],                            
                            ["Alexandre Casanova","Sound Recording"],
                            ["Ivan de Crecy","Production Assistant"]
                        ]
            },
        date: 2020,
        poster: `https://drive.google.com/file/d/1Ph1mqSqa30TbYAN0IwtNPFwfrzV1Z574/view?usp=sharing`,
        videoURL: ``,
        swatch1: "swatch-5",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    },
    {
        name: `swim-alone`, //Immutable Field
        title: `Swim Alone`,
        genre: `Short Film`,
        logline: `The dramatic depiction of a relationship's undramatic ending. What can you do when your partner starts to drift away but you don't know why?`,
        quote: ``,
        story: [`Swim Alone is not just a story about young love. It poses the question of how we emotionally process the end of a relationship when we cannot rationally explain it. What feeling do we decide to hold on to when we remember a person who is no longer part of our lives?`,
`Most love stories deal with relationships that crumble due to external conflict or as a result of a character’s actions. However, more often than not, relationships simply end because love fades or because one person drifts away needing to deal with things on their own. Swim Alone is about the latter, looking to capture the feeling of confusion, pain and melancholy that result.`],
        storyIMG: `../images/productions/SwimAlone/SA-1-IMG.png`,
        theFilmQuote: ``,
        theFilm: [`The first co-production between current Chief Brand Officer George and Writer/Director Guy (now CEO), Swim Alone is a labor of trial and error. Both had been working in related fields — as video advertising and theatre, respectively - and they were eager to discover what it meant to tell an emotional story through the medium of short film.`],
        theFilmIMG: `../images/productions/SwimAlone/SA-2-IMG.png`,
        theFilmIMG2: "",
        theChallengeQuote: ``,
        theChallenge: [`This was a true no-budget shoot. The very limited resources led to a swell of experimentation. From guerrilla shooting outdoors to recycling a scene in post-production, they learned that the budget was secondary to story and especially to the quality of crew. Today, we still use some of the guerrilla tactics they explored in Swim Alone and have worked with Swim Alone's crew many times over. `],
        theChallengeIMG: `../images/productions/SwimAlone/SA-3-IMG.png`,
        theChallengeIMG2: "",
        theInspirationQuote: ``,
        theInspiration: [`After numerous relationships in Guy's personal life collapsed when someone became overwhelmed by their own personal demons, he began to wonder if the sensation of being pushed aside by loved-ones was common to everyone. He tapped into the emotions of those experiences to create his first short.`],
        theInspirationIMG: `../images/productions/SwimAlone/SA-4-IMG.png`,
        crewAndCast: 
            {
                director: 'Guy Ettlin',
                cast:   [
                            ["Frederique Blanchard","Natalie"],
                            ["Francesca Scotti-Goetz","Emma"],
                            ["Nicole Arrage","Chief"]
                        ],
                crew:   [
                            ["Guy Ettlin","Director / Writer"],
                            ["George Popi","Director of Photography"],
                            ["Gaspard Nahmias","Gaffer / Grip"]
                        ]
            },
        date: 2020,
        poster: `https://drive.google.com/file/d/1Ph1mqSqa30TbYAN0IwtNPFwfrzV1Z574/view?usp=sharing`,
        videoURL: "https://player.vimeo.com/video/307154067",
        swatch1: "swatch-3",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    },
    {
        name: `senna`, //Immutable Field
        title: `Senna`,
        genre: `Documentary`,
        logline: `The agony of a still-born baby, the frustration of an indifferent society, and the pressure to move along.`,
        quote: ``,
        story: [`The agony of a still-born baby, the frustration of an indifferent society, and the pressure to move along. Senna follows a young couple struggling with every parent’s worst nightmare and their first three years coping with unimaginable loss. `],
        storyIMG: ``,
        theFilmQuote: ``,
        theFilm: [`Senna shines a light on the common, though still taboo, occurrence of still-born birth. We hope that by opening the discussion around grief, family, and what it means to be human, we can impact some of the institutional practices and social norms that continue to marginalize suffering families. `],
        theFilmIMG: ``,
        theFilmIMG2: "",
        theChallengeQuote: ``,
        theChallenge: [`The profound trauma at the centre of this production was difficult to navigate, especially as we sought to package it into a documentary. Witnessing the family’s pain, asking them to relive certain events, and exposing the heart of their grief put all our resolve to the test. Though we often questioned the process, we never doubted the importance of this story. Documentaries should be emotional and real, no matter how uncomfortable it makes us. `],
        theChallengeIMG: ``,
        theChallengeIMG2: "",
        theInspirationQuote: ``,
        theInspiration: [`Originally covered by journalist and director Sadia Raffiquddin, we heard about the situation through her and discussed the additional impact that a video documentary would possess over a written story. After much discussion, everyone agreed that it would be a worthwhile production to create. `],
        theInspirationIMG: ``,
        crewAndCast:
            {
                director: 'Sadia Rafiquddin',
                cast:   [
                            ["Senna Aubut","Self"],
                            ["Hannah Aubut","Self"],
                            ["Scott Aubut","Self"],
                            ["Cyan Aubut","Self"],
                            ["Hanzo the Dog","Self"]
                        ],
                crew:   [
                            ["Sadia Raffiquddin","Journalist/Director"],
                            ["George Popi","Editor / Cinematographer / Producer"],
                            ["Robin L’Hommeau","Cinematographer"]
                        ]
            },
        date: 2020,
        poster: `https://drive.google.com/file/d/1Ph1mqSqa30TbYAN0IwtNPFwfrzV1Z574/view?usp=sharing`,
        videoURL: ``,
        swatch1: "swatch-7",
        swatch2: "swatch-1",
        swatch3: "swatch-1"
    }
    // {
    //     name: ``,
    //     title: ``,
    //     genre: ``,
    //     logline: ``,
    //     quote: ``,
    //     story: ``,
    //     theFilmQuote: ``,
    //     theFilm: ``,
    //     theFilmIMG2: "",
    //     theChallengeQuote: ``,
    //     theChallenge: ``,
    //     theInspirationQuote: ``,
    //     theInspiration: ``,
    //     crewAndCast: 
    //         {
    //             director: '',
    //             cast:   [
    //                         ["",""],
    //                         ["",""]
    //                     ],
    //             crew:   [
    //                         ["",""],
    //                         ["",""]
    //                     ]
    //         },
    //     date: 2020,
    //     poster: ``,
    //     videoURL: ``,
    //     swatch1: ``,
    //     swatch2: ``,
    //     swatch3: ``,
    // },
];

export default productions