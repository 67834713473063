import React from 'react';
import axios from 'axios';


class Contact extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            value: '',
            name: '',
            email: '',
        };                    

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleChangeValue(event) {
        this.setState({value: event.target.value});
    }
    
    handleSubmit(event) {
        let companyKey = "OU^mEpfoht^aWDOO"; //############ CHANGE THIS ################
        let url = "https://dashboard.createkhaos.com/siteLink/postMessage/createKhaosCom/createKhaosCom.php"; //############ CHANGE THIS ################
        let mesObj = [{name:"source",value:"Ramen"},{name:"name",value:this.state.name},{name:"email",value:this.state.email},{name:"message",value:this.state.value}];
        let mes = JSON.stringify(mesObj);
        console.log(mesObj);
        let headers = new Headers();
        let fetchData = {
            method: 'POST',
            data: 
                { 
                    authKey: companyKey,
                    message: mes
                },
            headers: headers
        };
        console.log(fetchData)
        axios.post(url, fetchData)
            .then(function(response) {
                console.log("Great Success");
            })
        event.preventDefault();
    };
    


    render (){
        return (
            <>
                <main className="index body-set contact swatch-1">
                    <section>
                        <h1>Contact Us</h1>
                        <h2>Let's Talk</h2>
                        <p>
                        Have a story you want to tell? Chat with our team about how we can make your project come to life! 
                        <br />
                        It never hurts to ask.
                        </p>
                        <a href="mailto: contact@khaoslabs.com">Email Us</a>
                    </section>
                </main>
                <script src="../Logic/form.js" type="text/javascript" defer/>
                <script type="text/javascript" id="" src="https://dashboard.createkhaos.com/siteLink/postMessage/khaosLabsCom/khaosLabsCom-contactForm.js"/>
                <script type="text/javascript" id="" src="https://dashboard.createkhaos.com/siteLink/postMessage/khaosLabsCom/khaosLabsCom-emailGrab.js"/>
            </>
        );
    }
}

export default Contact;
