import React from 'react';
import TLINRList from '../Productions/TLINRList';

const TLINREp = ({match}) => {
    const epNum = match.params.name;
    const prod = TLINRList.find( tlinr => tlinr.epNum = epNum);
    
    const castMembers = [];
    const crewMembers = [];
    for (const cast of prod.crewAndCast.cast) {
        castMembers.push(
                    <tr>
                    <th>{cast[0]}
                    </th>
                    <th>
                        {cast[1]}
                    </th>
                    </tr>
        )
    } 

    for (const crew of prod.crewAndCast.crew) {
        crewMembers.push(
                    <tr>
                    <th className="actor-info">
                        {crew[0]}
                    </th>
                    <th className="actor-info">
                        {crew[1]}
                    </th>
                    </tr>
        )
    } 

    return (
        <>
            <main className="index body-set swatch-1">
                <section className="movie-header">
                    <h1 className={prod.swatch1}>{prod.title}</h1>
                    <h2>Dir. {prod.crewAndCast.director}, {prod.date}</h2>
                    <iframe title={prod.name} src={prod.videoURL} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </section>
                <section className="movie-info">
                    <h2>The Story</h2>
                    <h3>
                        {prod.quote}
                    </h3>
                    <p>
                        {prod.story}
                    </p>
                    <div className={prod.swatch2}>
                        <h2>The Film</h2>
                        <h3>
                            {prod.theFilmQuote}
                        </h3>
                        <p>
                            {prod.theFilm}
                        </p>
                    </div>
                    <h2>The Challenge</h2>
                    <h3>
                        {prod.theChallengeQuote}
                    </h3>
                    <p>
                        {prod.theChallenge}
                    </p>
                    <div className={prod.swatch3}>
                        <h2>The Inspiration</h2>
                        <h3>
                            {prod.theInspirationQuote}
                        </h3>
                        <p>
                            {prod.theInspiration}
                        </p>
                    </div>
                </section>
                <section className={`cast-info`}>
                    <h2>The Cast</h2>
                    <table className={`${prod.swatch1}`}>
                        {castMembers}
                    </table>
                </section>
                <section className="core-crew">
                    <h2>Core Crew</h2> 
                    <table className={`${prod.swatch1}`}>
                        {crewMembers}
                    </table>
                </section>
            </main>
        </>
    );
}

export default TLINREp;
