import React from 'react';
import { Link } from 'react-router-dom';
import productions from '../Productions/ProductionsList';
import PicAmD from '../resources/images/index/Poster-AmD.jpg';
import PicJes from '../resources/images/index/Poster-Jessica.jpg';
import PicSpark from '../resources/images/index/Poster-Spark.jpg';
import PicSA from '../resources/images/index/Poster-Swim Alone.png';
import PicSenna from '../resources/images/index/Poster-Senna.jpg';
import AmDNews from '../resources/images/index/AmD.jpg';
import SparkNews from '../resources/images/index/Spark.png';
import Searching from '../resources/images/index/Scripts.jpg';
import TLINR from '../resources/images/index/TLINR.png';
import PicBTS from '../resources/images/index/BTS.jpg';



function Home() {
    const AmD = productions.find (prod => prod.name === `american-dreamers` );
    const Jes = productions.find (prod => prod.name === `jessica` );
    const SA = productions.find (prod => prod.name === `swim-alone` );
    const Spark = productions.find (prod => prod.name === `spark` );
    const Senna = productions.find (prod => prod.name === `senna` );


    return (
    <>
      <main class="index body-set">
        <section class="body-section home-section">
            <h2>Productions</h2>
            <div class="content-section">
                <section className={`production-block ${Jes.swatch1} a-alt2`}>
                    <Link to="/productions/jessica">
                        <img src={PicJes} alt="Poster for the short film: Jessica" />
                        <h4>Short</h4>
                        <h3>Jessica</h3>
                    </Link>
                </section>
                <section className={`production-block ${Spark.swatch1} a-alt2`}>
                    <Link to="/productions/spark">
                        <img src={PicSpark} alt="Poster for the short film: Spark" />
                        <h4>Short</h4>
                        <h3>Spark</h3>
                    </Link>
                </section>
                <section class={`production-block ${AmD.swatch1} a-alt2`}>
                    <Link to="/productions/american-dreamers">
                        <img src={PicAmD} alt="Poster for the feature film: American Dreamers" />
                        <h4>Feature</h4>
                        <h3>American Dreamers</h3>
                    </Link>
                </section>
                <section className={`production-block ${SA.swatch1} a-alt2`}>
                    <Link to="/productions/swim-alone">
                        <img src={PicSA} alt="Poster for the short film: Swim Alone" />
                        <h4>Short</h4>
                        <h3>Swim Alone</h3>
                    </Link>
                </section>
                <section className={`production-block ${Senna.swatch1} a-alt2`}>
                    <Link to="/productions/senna">
                        <img src={PicSenna} alt="Poster for the documentary film: Senna" />
                        <h4>Documentary</h4>
                        <h3>Senna</h3>
                    </Link>
                </section>
            </div>
        </section>
        <section class="body-section home-section">
            <h2>In Progress</h2>
            <div class="content-section">
                <section class="news-block swatch-4 a-alt2">
                    <Link to="/productions/american-dreamers">
                        <img src={AmDNews} alt="" />
                        <h4>Watch Now</h4>
                        <h3>American Dreamers Trailer</h3>
                    </Link>
                </section>
                <section class="news-block swatch-5 a-alt2">
                    <Link to="/productions/spark">
                        <img src={SparkNews} alt="" />
                        <h4>Learn More</h4>
                        <h3>Spark is Complete</h3>
                    </Link>
                </section>
                <section class="news-block swatch-3 a-alt2">
                    <Link to="/contact-us">
                        <img src={Searching} alt="" />
                        <h4>Get In Touch</h4>
                        <h3>Looking for scripts</h3>
                    </Link>
                </section>
            </div>
        </section>
        <section class="body-section home-section">
            <h2>Behind The Scenes</h2>
            <div class="content-section">
                <section class="home-bts-block swatch-6 a-alt2">
                    <Link to="/bts">
                        <img src={PicBTS} alt="" />
                        <h4>Production BTS</h4><br/>
                        <p>Learn about the more obscure parts of the film industry in our podcast series ‘The Light is Not Right’, where we interview some of London’s premiere distribution execs, marketers and licensing specialists.</p>
                    </Link>
                </section>
                <section class="home-bts-block swatch-8 a-alt2">
                    <Link to="/tlinr/#spark">
                        <img src={TLINR} alt="" />
                        <h4>The Light Is Not Right</h4><br/>
                        <p>Learn about the more obscure parts of the film industry in our podcast series ‘The Light is Not Right’, where we interview some of London’s premiere distribution execs, marketers and licensing specialists.</p>
                    </Link>
                </section>
            </div>
        </section>
      </main>
    </>
  );
}

export default Home;
